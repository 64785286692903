// @flow
import React, { Component } from 'react';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import classnames from 'classnames';
import { MediaQuery, Logo } from '@riseart/common';
import { Section, Wrapper } from '@riseart/layout';
import { withForwardUrlACL } from 'shared_data/providers/acl/withForwardUrlACL';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import {
  getImageBySize,
  imageTypeKeysByScreenType,
} from 'shared_services/riseart/utils/ImageUtils';
import desktopRetina from 'shared_components/auth/containers/auth/images/ra-quiz-register-desktop-retina.jpg';
import desktopNormal from 'shared_components/auth/containers/auth/images/ra-quiz-register-desktop-normal.jpg';
import tabletRetina from 'shared_components/auth/containers/auth/images/ra-quiz-register-tablet-retina.jpg';
import tabletNormal from 'shared_components/auth/containers/auth/images/ra-quiz-register-tablet-normal.jpg';
import mobileRetina from 'shared_components/auth/containers/auth/images/ra-quiz-register-mobile-retina.jpg';
import mobileNormal from 'shared_components/auth/containers/auth/images/ra-quiz-register-mobile-normal.jpg';

import {
  raScreenXsMax,
  raScreenSm,
  raScreenMdMax,
  raScreenLg,
} from '@riseart/antd-provider/dist/website/variables.less';
import {
  authContainerCls,
  authContainerWithBackgroundCls,
  authContainerBackgroundCls,
  authContainerWrapperCls,
  authContainerFramedCls,
  authContainerHeaderCls,
  authContainerFormCls,
  authContainerFooterCls,
  authContainerSubFooterCls,
  authContainerLogoCls,
} from './Auth.less';

type Props = {
  images: Array<*>,
  history: Object,
  showLegalInfo: boolean,
  redirectByForwardACL: boolean,
  meIsLoaded: boolean,
  isUserLoggedIn: ?Object,
  sectionProps?: Object,
  children: any,
};

type State = {
  isLoading: boolean,
};

export const imagesSignIn = [
  {
    type: 'small',
    file: { url: mobileNormal },
  },
  {
    type: 'small-retina',
    file: { url: mobileRetina },
  },
  {
    type: 'medium',
    file: { url: tabletNormal },
  },
  {
    type: 'medium-retina',
    file: { url: tabletRetina },
  },
  {
    type: 'large',
    file: { url: desktopNormal },
  },
  {
    type: 'large-retina',
    file: { url: desktopRetina },
  },
];

export const AuthFramed = ({ children }: Object) => (
  <div className={authContainerFramedCls}>{children}</div>
);

export const AuthHeader = ({ children }: Object) => {
  const backgroundImage = getImageBySize(imagesSignIn, imageTypeKeysByScreenType.small).url;

  return (
    <MediaQuery minWidth={0}>
      <MediaQuery maxWidth={raScreenXsMax}>
        <div
          className={authContainerHeaderCls}
          style={
            backgroundImage
              ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }
              : null
          }
        >
          <div>{children}</div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={raScreenSm}>
        <div className={authContainerHeaderCls}>{children}</div>
      </MediaQuery>
    </MediaQuery>
  );
};

export const AuthLogo = () => (
  <div className={authContainerLogoCls}>
    <Logo theme="black" />
  </div>
);

export const AuthBody = ({ children }: Object) => (
  <div className={authContainerFormCls}>{children}</div>
);

export const AuthFooter = ({ children }: Object) => (
  <div className={authContainerFooterCls}>{children}</div>
);

export const AuthSubFooter = ({ children }: Object) => (
  <div className={authContainerSubFooterCls}>{children}</div>
);

/**
 * LoginContainer
 *
 */
class LoginContainer extends Component<Props, State> {
  shouldShowForm: boolean = false;

  /**
   * constructor
   *
   * @param {*} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.bindMethods();
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.onLoadingChange = this.onLoadingChange.bind(this);
  }

  /**
   * onLoadingChange
   */
  onLoadingChange: Function;

  onLoadingChange(loadingValue) {
    if (this.state.isLoading !== loadingValue) {
      this.setState({ isLoading: loadingValue });
    }
  }

  getPageSection = () => {
    const { meIsLoaded, redirectByForwardACL, sectionProps, children } = this.props;

    // update this.shouldShowForm only when me is loaded
    if (meIsLoaded && !this.shouldShowForm) {
      this.shouldShowForm = true;
    }

    return (
      <IsomorphicRipple
        isActive={this.state.isLoading || !meIsLoaded || redirectByForwardACL}
        isFullScreen={!this.shouldShowForm}
      >
        {this.shouldShowForm && (
          <Section
            {...sectionProps}
            className={classnames(authContainerCls, sectionProps && sectionProps.className, {
              [authContainerWithBackgroundCls]: !!this.props.images,
            })}
          >
            <Wrapper
              className={authContainerWrapperCls}
              sizes={{
                xs: 'wide',
                sm: 'narrow',
                md: 'narrow',
                lg: 'narrow',
                xl: 'narrow',
              }}
            >
              <div>
                {(typeof children === 'function' && children(this.onLoadingChange)) || children}
              </div>
            </Wrapper>
          </Section>
        )}
      </IsomorphicRipple>
    );
  };

  getImageContainer = (backgroundImage) => (
    <div
      className={backgroundImage ? authContainerBackgroundCls : null}
      style={
        backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
            }
          : null
      }
    >
      {this.getPageSection()}
    </div>
  );

  /**
   * render
   *
   * @returns {React$Element<div>}
   */
  render() {
    const { images } = this.props;

    return (
      <MediaQuery minWidth={0}>
        <MediaQuery maxWidth={raScreenXsMax}>
          {this.getPageSection(
            images ? getImageBySize(images, imageTypeKeysByScreenType.small).url : null,
          )}
        </MediaQuery>
        <MediaQuery minWidth={raScreenSm} maxWidth={raScreenMdMax}>
          {this.getImageContainer(
            images ? getImageBySize(images, imageTypeKeysByScreenType.medium).url : null,
          )}
        </MediaQuery>
        <MediaQuery minWidth={raScreenLg}>
          {this.getImageContainer(
            images ? getImageBySize(images, imageTypeKeysByScreenType.large).url : null,
          )}
        </MediaQuery>
      </MediaQuery>
    );
  }
}

export const AuthContainer = withTranslatedRouter(withForwardUrlACL(LoginContainer));
