// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { canUserAccessRoute } from '@riseart/fe-utils';
import { isRoutedUrl } from 'shared_services/riseart/utils/Utils';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { authRedirect } from 'shared_services/riseart/utils/AuthUtils';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { store as CONFIG_STORE, application as CONFIG_APP } from 'Config';

/**
 * HOC
 * @param {*} DecoratedComponent
 */
const HOC = (DecoratedComponent: Component<*, *>) =>
  class extends Component<*, *> {
    /**
     * shouldRedirect
     */
    shouldRedirect() {
      const { aclRole, isUserLoggedIn, location, history, locale, forwardUrl } = this.props;
      const queryParameters = queryString.parse(location.search);
      const url = queryParameters.forward || forwardUrl;
      // $FlowFixMe
      const forwardRouterConfig = url && isRoutedUrl(url);
      const forwardUrlAclRoles =
        (forwardRouterConfig && forwardRouterConfig.acl && forwardRouterConfig.acl.roles) || false;

      if (
        isUserLoggedIn &&
        (!url ||
          canUserAccessRoute(
            aclRole,
            forwardUrlAclRoles,
            CONFIG_APP.acl.rolesHierarchy,
            // $FlowFixMe
            queryParameters.role ? queryParameters.role.split(',') : null,
          ))
      ) {
        authRedirect({ ...queryParameters, forward: url }, history, locale, location);
        return true;
      }

      return false;
    }

    /**
     * render
     */
    render() {
      return <DecoratedComponent {...this.props} redirectByForwardACL={this.shouldRedirect()} />;
    }
  };

const mapStateToProps = (state) => ({
  aclRole: state[CONFIG_STORE.keys.auth].data.payload.acl_role,
  isUserLoggedIn: state[CONFIG_STORE.keys.me].data.user,
  meIsLoaded: state[CONFIG_STORE.keys.me].status.loaded,
  locale: localeSelector(state),
});

export const withForwardUrlACL = (DecoratedComponent: Component<*, *>) =>
  connect(mapStateToProps)(withTranslatedRouter(HOC(DecoratedComponent)));
